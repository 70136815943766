<template>
	<v-form ref="form">
		<v-container fluid>

			<v-row dense>
				<v-col cols="6">
					<date-input outlined dense v-model="formData.contract_date" label="Дата" :rules="[Validators.required()]" />
				</v-col>
				<v-col cols="6">
					<v-text-field outlined dense v-model="formData.voda_abonat_N" label="Абонатен номер за вода" :rules="[Validators.required()]" />
				</v-col>
			</v-row>

			<v-row dense>
				<v-col cols="12">
					<v-text-field outlined dense v-model="formData.client_name" label="Име" :rules="[Validators.required()]" />
				</v-col>
			</v-row>

			<v-row dense>
				<v-col cols="12">
					<v-text-field outlined dense v-model="formData.address" label="Адрес" :rules="[Validators.required()]" />
				</v-col>
			</v-row>

			<v-row dense>
				<v-col cols="6">
					<v-text-field outlined dense v-model="formData.client_phone" label="Телефон" :rules="[Validators.required()]" />
				</v-col>
				<v-col cols="6">
					<v-text-field outlined dense v-model="formData.client_email" label="Имейл" :rules="[Validators.email()]" />
				</v-col>
			</v-row>

			<v-row dense>
				<v-col cols="12">
					<v-combobox
						outlined dense
						v-model="formData.device_list"
						chips
						clearable
						label="Списък номера на водомери"
						multiple
						inputmode="numeric"
						:rules="[Validators.required()]"
					>
						<template v-slot:selection="{ attrs, item, select, selected }">
							<v-chip
								small
								v-bind="attrs"
								:input-value="selected"
								close
								@click="select"
								@click:close="removeDevice(item)"
							>
								{{ item }}
							</v-chip>
						</template>
					</v-combobox>
				</v-col>
			</v-row>

			<div class="">Подпис:</div>
			<signature-pad
				v-model="formData.signature_svg"
				ref="signature"
				:is-signed="isSigned"
				@update:isSigned="$emit('update:isSigned', $event)"
				width="100%" height="320px"
				required
			/>
			<div class="text-right mt-2" v-if="!hideClearSignature">
				<v-btn small color="primary" @click="clearSignature" :disabled="formData.signature_svg === ''">
					Изчисти
				</v-btn>
			</div>

		</v-container>
	</v-form>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import {phpDate} from "@/ittijs/utils";
import DateInput from "@/ittijs/Inputs/DateInput.vue";
import Validators from "@/ittijs/Validators";
import Model from "./Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import SignaturePad from "@/ittijs/components/SignaturePad.vue";

const model = ITTIModelProvider.getModel(Model);

export default {
	components: {SignaturePad, DateInput},
	props: {
		imotData: Object,
		hideClearSignature: Boolean,
		isSigned: Boolean,
		deviceList: Array,
	},
	data() {
		return {
			model,
			formData : {
				contract_date : phpDate('Y-m-d'),
				client_name   : this.imotData.client_name,
				address       : this.imotData.address,
				client_phone  : this.imotData.client_phone,
				client_email  : this.imotData.client_email,
				voda_abonat_N : this.imotData.voda_abonat_N,
				device_list   : [...(this.deviceList??[])],
				signature_svg : '',
			},
		};
	},
	computed: {
		Validators: () => Validators,
	},
	methods: {
		removeDevice(item) {
			this.formData.device_list.splice(this.formData.device_list.indexOf(item), 1);
		},
		clearSignature() {
			this.$refs.signature.clearSignature();
		},
		async submit() {
			if (this.$refs.form.validate()) {
				return await model.createVodaContract(
					this.imotData.id,
					this.formData.contract_date,
					this.formData.client_name,
					this.formData.address,
					this.formData.client_phone,
					this.formData.client_email,
					this.formData.voda_abonat_N,
					this.formData.device_list,
					this.formData.signature_svg,
				);
			}
		},
	},
}
</script>