<template>
	<v-container fluid>
		<data-loader
			ref="loader"
			:watch="id"
			:callback="()=>model.getJobData(id)"
			:data.sync="data"
		>
			<template #loading>
				<v-progress-linear indeterminate rounded />
			</template>
			<template #default>
				<div v-if="data">
					<v-sheet>
						<v-toolbar flat dense rounded :color="getCardColor(data.job.status)" class="mb-2">
							<v-btn @click="doClose" icon>
								<v-icon>mdi-chevron-left</v-icon>
							</v-btn>
							<v-toolbar-title>
								<time-period-format
									v-if="data.job.scheduled_time_start"
									:start="data.job.scheduled_time_start"
									:duration="parseInt(data.job.scheduled_duration_minutes)"
								></time-period-format><time-period-format
									v-else-if="data.job.time_started"
									:start="data.job.time_started.slice(-8)"
									:end="data.job.time_completed?.slice(-8)"
								></time-period-format>, {{ data.imot.address }}

							</v-toolbar-title>
							<imot-edit-popup :imot="data.imot.id" v-if="!readonly" />
							<v-spacer/>
							<v-btn target="_blank" :href="navLink" class="ml-2">
								<v-icon left>mdi-map-marker-radius</v-icon>
								Карта
							</v-btn>
							<v-btn v-if="isToday && status==='scheduled'" color="success" class="ml-2" @click="doStartJob">
								Започни
							</v-btn>
							<v-btn v-if="isToday && status==='completed'" color="success" class="ml-2" @click="doReopenJob">
								Редакция
							</v-btn>
							<v-btn v-else-if="(isToday || isBeforeToday) && status==='initiated'" color="success" class="ml-2" @click="doCompleteJob">
								Приключи
							</v-btn>
							<confirm-dialog ref="confirmCompleteOtchet"
								title="Потвърждение"
								label-confirm="Приключи заявката"
								color-confirm="red"
								label-cancel="Върни се обратно"
								:max-width="600"
							>
								<v-alert text type="warning" icon="mdi-alert-outline">
									Внимание! Проблеми с приключване на заявката:
									<ul>
										<li v-show="warnOtchet">Не е генериран PDF отчет</li>
										<li v-show="warnVodaContract">Не е създаден договор за отчет на студ. вода</li>
										<li v-show="warnDevReplaceValid">Липсват данни за сменен уред</li>
										<li v-show="warnTalonPlombi">Не е попълнен талон за пломбиране</li>
									</ul>
								</v-alert>
							</confirm-dialog>
						</v-toolbar>

						<template v-if="!readonly">
							<v-alert v-if="saveLoading" dense text type="warning" class="mb-2">
								<template #prepend>
									<v-progress-circular class="v-alert__icon" indeterminate size="24" width="2" />
								</template>
								Записване...
							</v-alert>
							<v-alert v-else-if="saveError" dense text type="error" class="mb-2">
								{{ saveError }}
								<template #append>
									<v-btn small color="error" @click="saveDebounced">Опитай отново</v-btn>
								</template>
							</v-alert>
							<v-alert v-else dense text type="success" icon="mdi-checkbox-marked-circle-outline" class="mb-2">
								Данните са записани!
							</v-alert>
						</template>

						<v-tabs v-model="tabs" class="mb-2">
							<v-tab>Услуги</v-tab>
							<v-tab>Бележки</v-tab>
						</v-tabs>

						<v-tabs-items v-model="tabs" touchless>
							<v-tab-item class="pb-2">

								<key-val-table
									dense
									class="elevation-2"
									:compact="$vuetify.breakpoint.smAndDown"
									:data="[
										{key: 'imot_N',     label: 'Имот номер',         val: data.imot.imot_N},
										{key: 'client_N',   label: 'Абонат номер',       val: data.imot.client_N},
										{key: 'name',       label: 'Име на абоната',     val: data.imot.client_name},
										{key: 'phone',      label: 'Телефон 1',          val: data.imot.client_phone},
										{key: 'phone2',     label: 'Телефон 2',          val: data.imot.client_phone2},
										{key: 'email',      label: 'Имейл',              val: data.imot.client_email},
										{key: 'station_N',  label: 'Станция номер',      val: data.station.station_N},
									]"
								>
									<template #td.phone="{val}"><a v-if="val && val!==''" :href="'tel:'+val">{{ val }}</a></template>
									<template #td.phone2="{val}"><a v-if="val && val!==''" :href="'tel:'+val">{{ val }}</a></template>
									<template #td.email="{val}"><a v-if="val && val!==''" :href="'mailto:'+val">{{ val }}</a></template>
								</key-val-table>

								<v-container>
									<v-row dense>
										<v-col>
											<v-textarea v-model="data.job.notes_operator" outlined dense hide-details label="Бележки от оператор" class="mt-2" disabled readonly />
										</v-col>
										<v-col>
											<v-textarea v-model="data.job.notes_montajnik" outlined dense hide-details label="Бележки от монтажник" class="mt-2" :disabled="readonly" :readonly="readonly" />
										</v-col>
									</v-row>
								</v-container>

								<JobItemsList
									ref="JobItemsList"
									class="mt-2"
									:job-id="id"
									:imot="data.job.imot_id"
									v-model="servicesData"
									is-montajnik
									:readonly="readonly"
									show-payment-type
									:payment-type.sync="data.job.payment_type"
									:is-missing-data.sync="isMissingDeviceData"
								/>

								<CreateUP
									v-if="!readonly"
									signature
									up-type="godishen"
									:imot="data.job.imot_id"
									@reload="onCreateUPReload"
								/>

								<send-otchet
									ref="otchetDialog"
									:job-id="data.job.id"
									:color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'"
									small class="ml-2"
									@ready="isOtchetSent = true"
								/>

								<voda-contract-popup
									:imot-data="data.imot"
									:device-list="vodaContractDevices"
									@success="isDogovorVodaCreated = true; snackbar = true;"
								/>

								<protocol-findings-popup :job-id="data.job.id" @success="snackbar = true" />

								<talon-popup-new
									:job-id="data.job.id"
									:imodel="model"
									@success="isTalonPlombiCreated = true; snackbar = true;"
								>
									<template #activator="{attrs,on}">
										<v-btn
											v-bind="attrs"
											v-on="on"
											:color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'"
											small class="ml-2"
										>Талон пломбиране</v-btn>
									</template>
								</talon-popup-new>

								<v-snackbar color="success" v-model="snackbar" timeout="2000">
									Успех!
								</v-snackbar>

							</v-tab-item>
							<v-tab-item>

								<v-container fluid>
									<v-row>
										<v-col lg="4" md="6" cols="12">
											<v-card outlined>
												<v-card-title>Бележки за имота</v-card-title>
												<v-card-text>
													<imot-data-notes
														:imot="data.imot.id"
													/>
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
								</v-container>

							</v-tab-item>
						</v-tabs-items>

					</v-sheet>
				</div>
			</template>
		</data-loader>
	</v-container>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "./Model";
import JobItemsList from "@/views/frontdesk/JobItemsList.vue";
import KeyValTable from "@/ittijs/components/KeyValTable.vue";
import {debounceAsync, phpDate, stationMapUrl} from "@/ittijs/utils";
import CreateUP from "@/ittijs/components/UvedomitelniPisma/CreateUP.vue";
import TimePeriodFormat from "@/ittijs/components/TimePeriodFormat.vue";
import ImotDataNotes from "@/views/clients_screen/ImotDataNotes.vue";
import ImotEditPopup from "@/views/frontdesk/ImotEditPopup.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import VodaContractPopup from "@/views/imot/VodaContractPopup.vue";
import {DeviceType, JobServiceIDs} from "@/ittijs/Nomenclatures";
import SendOtchet from "@/views/montaji/montajnik_ui/SendOtchet.vue";
import ConfirmDialog from "@/ittijs/ConfirmDialog.vue";
import ProtocolFindingsPopup from "@/views/montaji/montajnik_ui/ProtocolFindingsPopup.vue";
import TalonPopupNew from "@/ittijs/components/TalonPlombi/TalonPopupNew.vue";

/* eslint-disable @typescript-eslint/camelcase */

const model = ITTIModelProvider.getModel(Model);

export default {
	components: {
		TalonPopupNew,
		ProtocolFindingsPopup,
		ConfirmDialog,
		SendOtchet,
		VodaContractPopup,
		ImotEditPopup,
		ImotDataNotes,
		TimePeriodFormat,
		CreateUP,
		KeyValTable,
		JobItemsList,
		DataLoader,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		})
	],
	props: {
		utility: {},
		date: {},
		id: {},
	},
	data(){
		return {
			model,
			tabs: null,
			data: null,
			snackbar: false,
			servicesData: null,
			saveDebounced: debounceAsync(this.startSave, 500),
			saveLoading: false,
			saveError: null,
			isOtchetSent: false,
			isDogovorVodaCreated: false,
			isTalonPlombiCreated: false,
			isMissingDeviceData: false,
		}
	},
	computed: {
		status(){
			return this.data && this.data.job.status || null;
		},
		readonly(){
			return !((this.isToday || this.isBeforeToday) && this.data?.job?.status === 'initiated' || false);
		},
		isToday(){
			return this.data?.job?.date === phpDate('Y-m-d', new Date) || false;
		},
		isBeforeToday(){
			return this.data?.job?.date < phpDate('Y-m-d', new Date) || false;
		},
		navLink(){
			return stationMapUrl(
				this.data.station,
				this.data.station.city,
				this.data.imot && this.data.imot.address
					? this.data.imot.address
					: this.data.station.address
			);
		},
		hasOtchetInput(){
			// дали нещо е попълвано за отчет
			return Object.values(this.servicesData?.otcheti ?? {}).some(
				otchet => (otchet.value??'') != '' || (otchet.value_mem??'') != ''
			);
		},
		dataForSave(){
			if (this.data === null) return null;
			if (this.servicesData === null) return null;
			return {
				job: {
					notes_montajnik: this.data.job.notes_montajnik,
					payment_type: this.data.job.payment_type,
				},
				services: this.servicesData,
			}
		},
		vodaContractDevices() {
			// collect all vodomer numbers, including just inserted/updated ones
			return this.servicesData?.values_device
				?.map(item => {
					if (item.dev_new__device_type_code !== DeviceType.VODOMER_COLD && item.dev_new__device_type_code !== DeviceType.VODOMER_HOT) {
						return null;
					}
					if (item.is_declined === '1' || item.is_declined === true) {
						if (item.device_id === null) return null;
						return item.dev_old__device_N;
					}
					return item.dev_new__device_N || item.dev_old__device_N || null;
				})
				.filter(v => v !== null);
		},
		needsDogovorVoda(){
			return (this.servicesData?.values_imot ?? []).some(
				service => service.service_id == JobServiceIDs.DogovorVoda && service.enabled
			);
		},
		needsTalonPlombi(){
			// collect all vodomer numbers, including just inserted/updated ones
			return this.servicesData?.values_device
				?.some(item => {
					if (item.dev_new__device_type_code !== DeviceType.VODOMER_COLD && item.dev_new__device_type_code !== DeviceType.VODOMER_HOT) {
						return false;
					}
					return true;
				}) ?? false;
		},
		warnOtchet(){
			return this.hasOtchetInput && !this.isOtchetSent;
		},
		warnVodaContract(){
			return this.needsDogovorVoda && !this.isDogovorVodaCreated;
		},
		warnDevReplaceValid(){
			return this.isMissingDeviceData;
		},
		warnTalonPlombi(){
			return this.needsTalonPlombi && !this.isTalonPlombiCreated;
		},
		warnALL(){
			return this.warnOtchet || this.warnVodaContract || this.warnDevReplaceValid || this.warnTalonPlombi;
		},
	},
	watch: {
		dataForSave: {
			deep: true,
			handler(val, old){
				if (old !== null && !this.readonly) {
					this.saveDebounced();
				}
			}
		},
	},
	methods: {
		doClose(){
			this.$router.push({
				name: this.$route.name,
				params: {
					utility: this.$route.params.utility,
					date: this.$route.params.date,
				},
			})
		},
		getCardColor(status){
			if (status === 'completed') {
				return this.$vuetify.theme.dark ? 'teal darken-3' : 'green lighten-4';
			}
			if (status === 'initiated') {
				return this.$vuetify.theme.dark ? 'deep-orange darken-4' : 'orange lighten-4';
			}
			return this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4';
		},
		doStartJob(){
			const callback = async coords => {
				await model.startJob(this.data.job.id, coords);
				this.$refs.loader.loadDebounce();
			};
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					position => callback({
						accuracy         : position.coords.accuracy,
						altitude         : position.coords.altitude,
						altitudeAccuracy : position.coords.altitudeAccuracy,
						heading          : position.coords.heading,
						latitude         : position.coords.latitude,
						longitude        : position.coords.longitude,
						speed            : position.coords.speed,
					}),
					() => callback(null)
				);
			}
			else {
				callback(null);
			}
		},
		async doReopenJob(){
			await model.reopenJob(this.data.job.id);
			this.$refs.loader.loadDebounce();
		},
		async doCompleteJob(){
			if (this.warnALL && !await this.$refs.confirmCompleteOtchet.open()) {
				return;
			}
			await model.completeJob(this.data.job.id);
			this.$router.back();
		},
		onCreateUPReload(){
			this.$refs.JobItemsList.reload();
		},
		async startSave(){
			this.saveError = null;
			this.saveLoading = true;
			try {
				await model.updateJob(this.data.job.id, this.dataForSave);
			}
			catch (e) {
				this.saveError = e.errors?.form?.pop() ?? e;
			}
			this.saveLoading = false;
		},
	},
}
</script>