/* eslint-disable @typescript-eslint/camelcase */
import {ITTIModelBase} from '@/ittijs/ITTIModel';
export default class extends ITTIModelBase {
	constructor() {
		super('MontajnikUI');
	}
	getDates(utility) {
		return this.fetch('getDates', {utility});
	}
	getDailySchedule(utility, date){
		return this.fetch('getDailySchedule', {utility, date});
	}
	getJobData(jobId){
		return this.fetch('getJobData', {id: jobId});
	}
	createJob(utility, imotId, date) {
		return this.fetch('createJob', {utility, date}, {imotId});
	}
	createJobs(utility, imotIds, date) {
		return this.fetch('createJobs', {utility, date}, {imotIds});
	}
	startJob(jobId, coords) {
		return this.fetch('startJob', {id: jobId}, {coords});
	}
	reopenJob(jobId) {
		return this.fetch('reopenJob', {id: jobId}, {});
	}
	updateJob(jobId, data) {
		return this.fetch('updateJob', {id: jobId}, data);
	}
	completeJob(jobId) {
		return this.fetch('completeJob', {id: jobId}, {});
	}
	sendOtchetEmail(jobId, skipSend, emailAddress, storeEmail) {
		return this.fetch('sendOtchetEmail', {id: jobId}, {skipSend, emailAddress, storeEmail});
	}
	createProtocolFindings(jobId, findings, signature_svg) {
		return this.fetch('createProtocolFindings', {jobId}, {findings, signature_svg});
	}
}