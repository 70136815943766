<template>
	<div style="display: contents;">
		<data-loader :watch="utility" :callback="()=>model.getDates(utility)" :data.sync="dates">
			<template #loading>
				<v-progress-linear indeterminate rounded />
			</template>
			<template #default>
				<v-tabs v-if="dates?.length" v-model="dateIndex" show-arrows>
					<v-tab v-for="date in dates" :key="date" v-bind="tabProps(date)">{{ formatDate(date) }}</v-tab>
				</v-tabs>
				<v-alert v-else-if="dates" type="info" class="ma-3" text outlined>
					Няма задачи в графика
				</v-alert>
			</template>
		</data-loader>

		<v-container fluid v-if="dates?.length && date">

			<data-loader :watch="utility && date && {utility, date} || null" :callback="()=>model.getDailySchedule(utility, date)">
				<template #loading>
					<v-progress-linear indeterminate rounded />
				</template>
				<template #default="{data,reload}">
					<div style="max-width: 800px;" v-if="data">
						<v-sheet rounded outlined class="pa-1">
							<div style="white-space: pre-wrap;" v-text="data.notes" />
							<ep-list accordion multiple>
								<ep-panel v-for="item in data.device_counts" :key="item.type">
									<ep-header>
										{{DeviceTypeLabels.get(item.type)}}
										<v-spacer/>
										<div class="text-right mr-2">
											<v-chip color="primary" small>
												{{ item.count }}
											</v-chip>
										</div>
									</ep-header>
									<ep-content>
										<v-simple-table dense>
											<thead>
											<tr>
												<th>Марка</th>
												<th>Модел</th>
												<th>Радио</th>
												<th class="text-right">Брой монтажи</th>
											</tr>
											</thead>
											<tbody>
											<tr v-for="completed in item.completed" :key="completed.model?.id ?? 0">
												<td>{{completed.model?.brand}}</td>
												<td>{{completed.model?.model}}</td>
												<td>
													<v-icon color="green" v-if="completed.model?.is_radio=='1'" title="Радио">mdi-wifi</v-icon>
													<v-icon color="grey" v-else-if="completed.model" title="Радио">mdi-wifi-off</v-icon>
												</td>
												<td class="text-right">{{completed.count}}</td>
											</tr>
											</tbody>
										</v-simple-table>
									</ep-content>
								</ep-panel>
							</ep-list>
						</v-sheet>
						<v-timeline dense align-top>

							<v-btn
								v-if="isToday && data.allow_unplanned_jobs"
								absolute top right fab small color="primary"
								@click="doAddJob"
							>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
							<job-add-popup ref="jobAddPopup"
								v-if="isToday && data.allow_unplanned_jobs"
								:imodel="model"
								:date="date"
								@ready="doOpenJob($event)"
								@reload="reload"
							/>

							<v-timeline-item v-for="(item, index) in data.jobs" :key="index" small>
								<v-row>
									<v-col cols="2">
										<time-period-format
											v-if="item.job.scheduled_time_start"
											:start="item.job.scheduled_time_start"
											:duration="parseInt(item.job.scheduled_duration_minutes)"
										></time-period-format>
										<time-period-format
											v-else-if="item.job.time_started"
											:start="item.job.time_started.slice(-8)"
											:end="item.job.time_completed?.slice(-8)"
										></time-period-format>

										<time-remaining v-if="showTimeRemaining(item)" class="mt-2" :time="item.job.scheduled_time_start" />
									</v-col>
									<v-col cols="10">
										<v-card :color="getCardColor(item)" elevation="2" @click="doOpenJob(item.job.id)">
											<v-card-title>{{ item.imot.imot_N }}: {{ item.imot.address }}</v-card-title>
											<v-card-subtitle>{{item.imot.client_name}}</v-card-subtitle>
											<v-card-text>
												<div class="body-1" v-html="item.services_summary" />
												<div class="body-1" v-if="item.job.notes_operator">
													<strong>Бележки оператор:</strong><br>
													<div style="white-space: pre-wrap;">{{item.job.notes_operator}}</div>
												</div>
											</v-card-text>
										</v-card>
									</v-col>
								</v-row>
							</v-timeline-item>
						</v-timeline>
					</div>
				</template>
			</data-loader>
		</v-container>
	</div>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "./Model";
import {isoToDate, phpDate} from "@/ittijs/utils";
import TimePeriodFormat from "@/ittijs/components/TimePeriodFormat.vue";
import TimeRemaining from "@/ittijs/components/TimeRemaining.vue";
import {DeviceTypeLabels, monthsShort, weekdaysShort} from "@/ittijs/Nomenclatures";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import JobAddPopup from "@/views/montaji/montajnik_ui/JobAddPopup.vue";
import ExpansionPanelShort from "@/ittijs/ExpansionPanelShort";

const model = ITTIModelProvider.getModel(Model);

export default {
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			date: 'innerDate',
		}),
		ExpansionPanelShort,
	],
	components: {JobAddPopup, TimeRemaining, TimePeriodFormat, DataLoader},
	props: {
		utility: {},
		date: {},
	},
	mounted() {
		this.setIntervalHandle = setInterval(() => {
			this.today = phpDate('Y-m-d', new Date);
		}, 1000);
	},
	beforeDestroy() {
		clearInterval(this.setIntervalHandle);
		this.setIntervalHandle = null;
	},
	data(){
		return {
			model,
			dates: [],
			dateIndex: null,
			innerDate: null,
			today: phpDate('Y-m-d', new Date),
			setIntervalHandle: null,
		}
	},
	computed: {
		DeviceTypeLabels() {
			return DeviceTypeLabels
		},
		isToday(){
			return this.date === this.today;
		},
	},
	watch: {
		dates(){
			this.autoSelectFirstDate();
		},
		dateIndex(val){
			if (this.dates?.length) {
				this.innerDate = this.dates && this.dates[val] || null;
			}
		},
		date(){
			if (this.dates?.length) {
				this.autoSelectFirstDate();
			}
		},
	},
	methods: {
		autoSelectFirstDate(){
			if (this.utility && this.dates?.length) {
				// if there's a date prop, try to match it
				if (this.date) {
					for (const index in this.dates) {
						if (this.dates[index] === this.date) {
							this.dateIndex = parseInt(index);
							this.innerDate = this.dates[index];
							return;
						}
					}
				}
				// try to select today
				for (const index in this.dates) {
					if (this.dates[index] === this.today) {
						this.dateIndex = parseInt(index);
						this.innerDate = this.dates[index];
						return;
					}
				}
				// give up, first tab will be selected
				this.dateIndex = null;
				this.innerDate = null;
			}
		},
		formatDate(date){
			date = isoToDate(date);
			if (date) {
				return `${weekdaysShort[date.getDay()]}, ${date.getDate()} ${monthsShort[date.getMonth()]}`;
			}
			return null;
		},
		tabProps(date){
			return date == this.today ? {
				"class": this.$vuetify.theme.dark ? 'cyan darken-4' : 'cyan lighten-5',
			} : {
			};
		},
		getCardColor(item){
			if (item.job.status === 'completed') {
				return this.$vuetify.theme.dark ? 'teal darken-3' : 'green lighten-4';
			}
			if (item.job.status === 'initiated') {
				return this.$vuetify.theme.dark ? 'orange darken-4' : 'orange lighten-4';
			}
			return this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4';
		},
		showTimeRemaining(item){
			if (this.date !== phpDate('Y-m-d', new Date)) return false;
			if (!item.job.scheduled_time_start) return false;
			if (item.job.status === 'scheduled') return true;
			return false;
		},
		doStartJob(jobId){
			const callback = async coords => {
				await model.startJob(jobId, coords);
				this.doOpenJob(jobId);
			};
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					position => callback({
						accuracy         : position.coords.accuracy,
						altitude         : position.coords.altitude,
						altitudeAccuracy : position.coords.altitudeAccuracy,
						heading          : position.coords.heading,
						latitude         : position.coords.latitude,
						longitude        : position.coords.longitude,
						speed            : position.coords.speed,
					}),
					() => callback(null)
				);
			}
			else {
				callback(null);
			}
		},
		doOpenJob(jobId){
			this.$router.push({
				name: this.$route.name,
				params: {
					utility: this.utility,
					date: this.date,
					id: jobId,
				},
			});
		},
		doAddJob(){
			this.$refs.jobAddPopup.open();
		},
	},
}
</script>