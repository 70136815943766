<template>
	<my-dialog ref="dialog" max-width="800" title="Договор за отчет на вода">
		<template #activator="{attrs,on}">
			<v-btn v-bind="attrs" v-on="on" small :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'" class="ml-2">
				Договор за отчет на вода
			</v-btn>
		</template>
		<voda-contract-form
			v-bind="$attrs"
			ref="form"
			hide-clear-signature
			:is-signed.sync="isSigned"
		/>
		<template #actions>
			<v-btn :disabled="!isSigned" @click="$refs.form.clearSignature()">
				Нов подпис
			</v-btn>
			<v-spacer/>
			<btn-loading-promise :click="doSubmit">
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading">Потвърди</v-btn>
				</template>
			</btn-loading-promise>
		</template>
	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import VodaContractForm from "@/views/imot/VodaContractForm.vue";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";

export default {
	components : {BtnLoadingPromise, VodaContractForm, MyDialog},
	data() {
		return {
			isSigned: false,
		}
	},
	methods: {
		async doSubmit() {
			if (await this.$refs.form.submit()) {
				this.$refs.dialog.doClose();
				this.$emit('success');
			}
		},
	},
}
</script>