<template>
	<div style="display: inline-block" :class="{expired}">
		<v-icon>mdi-clock-outline</v-icon>
		{{ expired ? '+ ' : '' }}{{ remainingAbsTime?.toString() }}
	</div>
</template>

<script>
import {TimeOfDay} from "@/ittijs/utils";

const getNow = function(){
	const now = new Date;
	return new TimeOfDay(now.getHours() * 60 + now.getMinutes());
};

export default {
	props: {
		time: String,
	},
	data() {
		return {
			intervalHandle: null,
			now: getNow(),
		}
	},
	computed: {
		parsedTime() {
			return this.time && TimeOfDay.fromString(this.time) || null;
		},
		remainingMinutes() {
			return this.parsedTime && this.now && (this.now.minutes() - this.parsedTime.minutes()) || null;
		},
		remainingAbsTime() {
			return this.remainingMinutes !== null && new TimeOfDay(Math.abs(this.remainingMinutes)) || null;
		},
		expired(){
			return this.remainingMinutes > 0;
		},
	},
	beforeMount() {
		this.intervalHandle = setInterval(this.onInterval, 1000);
	},
	beforeDestroy() {
		clearInterval(this.intervalHandle);
	},
	methods: {
		onInterval() {
			this.now = getNow();
		},
	},
}
</script>

<style lang="sass" scoped>
::v-deep.expired
	color: #E53935
	font-weight: bold
	i.v-icon
		color: inherit
</style>