<template>
	<my-dialog @open="onOpen" v-model="dialog" persistent title="Талон за пломбиране на водомери" max-width="900">
		<template #activator="{attrs,on}">
			<slot name="activator" v-bind="{attrs,on}"></slot>
		</template>
		<data-loader :callback="()=>imodel.fetch('talonPlombiInit', {jobId})"
			:data.sync="data"
		>
			<template #loading>
				<v-progress-linear indeterminate />
			</template>

			<v-form ref="form" v-model="isFormValid">
				<Talon :data.sync="data">
					<template #container-top="{data}">
						<v-row dense v-if="data">
							<v-col>
								<v-text-field
									label="Имейл адрес за получаване на талона"
									type="email" outlined dense clearable hide-details="auto"
									:rules="[Validators.email()]"
									v-model="data.email"
								/>
							</v-col>
							<v-col>
								<v-checkbox
									dense hide-details="auto"
									label="Запиши имейла в профила на имота"
									v-model="data.emailUpdateImot"
								/></v-col>
						</v-row>
					</template>
					<template #container-bottom="{data}">
						<v-row dense v-if="data">
							<v-col>
								<signature-pad
									v-model="data.signature_svg"
									ref="signature"
									:is-signed.sync="isSigned"
									width="100%" height="320px"
									required
								/>
								<v-btn small text @click="$refs.signature.clearSignature()" :disabled="!isSigned">Нов подпис</v-btn>
							</v-col>
						</v-row>
					</template>
				</Talon>
				<v-snackbar color="error" v-model="errorSnackbar" timeout="2000">
					Грешка при валидация
				</v-snackbar>
			</v-form>

		</data-loader>
		<template #actions>
			<v-btn small text @click="dialog = false">Откажи</v-btn>
			<v-spacer />
			<btn-loading-promise :click="submit">
				<template #default="{click,loading}">
					<v-btn small color="primary" @click="click" :loading="loading">Създай талон</v-btn>
				</template>
			</btn-loading-promise>
		</template>
	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import Talon from "@/ittijs/components/TalonPlombi/Talon.vue";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import IModelMixin from "@/ittijs/IModelMixin";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import Validators from "@/ittijs/Validators";
import SignaturePad from "@/ittijs/components/SignaturePad.vue";

export default {
	computed: {
		Validators() {
			return Validators
		}
	},
	components : {SignaturePad, DataLoader, BtnLoadingPromise, Talon, MyDialog},
	mixins: [
		IModelMixin,
		ThrowMeMixin,
	],
	props: {
		jobId: {},
	},
	data(){
		return {
			data: null,
			dialog: false,
			isFormValid: false,
			isSigned: false,
			errorSnackbar: false,
		}
	},
	methods: {
		onOpen(){
			this.data = null;
		},
		async submit(){
			if (!(this.$refs.form.validate() && this.isSigned)) {
				this.errorSnackbar = true;
				return;
			}
			try {
				await this.imodel.fetch('talonPlombiSubmit', {jobId : this.jobId}, this.data);
				this.dialog = false;
				this.$emit('success');
			}
			catch (e) {
				this.throwMe(e);
			}
		},
	},
}
</script>