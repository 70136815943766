<template>
	<please-select-utility alert-class="ma-3">
		<component :is="component" v-bind="$route.params" />
	</please-select-utility>
</template>

<script>
import DailySchedule from "@/views/montaji/montajnik_ui/DailySchedule.vue";
import JobView from "@/views/montaji/montajnik_ui/JobView.vue";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";

export default {
	components: {PleaseSelectUtility, DailySchedule, JobView},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		}),
	],
	props: {
		utility: {},
		date: {},
		id: {},
	},
	computed: {
		component(){
			return this.$route.params.id ? 'JobView' : 'DailySchedule';
		},
	},
	watch: {
		utility(utility){
			this.$router.replace({
				name: this.$route.name,
				params: {
					utility,
					date: null,
					id: null,
				},
			});
		},
	},
}
</script>