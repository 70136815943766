<template>
	<my-dialog ref="dialog" v-model="dialog" title="Непланирана задача" max-width="1200">
		<v-container fluid>
			<quick-search-set :filter-group.sync="filterQuick">
				<v-row dense>
					<v-col cols="4">
						<quick-search :imodel="imotModel" field="station_id" :op="FilterOps.EQUALS">
							<template #default="{rule}">
								<station-select-all v-model="rule.value" outlined dense label="Станция" hide-details clearable />
							</template>
						</quick-search>
					</v-col>
					<v-col cols="4"><quick-search :imodel="imotModel" field="imot_N" :op="FilterOps.BEGINS" hide-details /></v-col>
					<v-col cols="4"><quick-search :imodel="imotModel" field="keywords" label="Адрес, име, телефон" :op="FilterOps.CONTAINS" hide-details /></v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" class="d-flex">
						<quick-search :imodel="imotModel" field="is_device_replace" :op="FilterOps.EQUALS">
							<template #default="{rule}">
								<v-checkbox v-model="rule.value" label="С уреди за смяна" class="mr-2" />
							</template>
						</quick-search>
						<quick-search :imodel="imotModel" field="is_job_exists" :op="FilterOps.EQUALS_NOT">
							<template #default="{rule}">
								<v-checkbox v-model="rule.value" label="Без заявка" class="mr-2" />
							</template>
						</quick-search>
						<v-spacer/>
					</v-col>
				</v-row>
			</quick-search-set>
			<List
				:imodel="imotModel" :filter="filter"
				headers="imot_N,address,client_name,is_device_replace,is_job_exists"
				hide-edit hide-export hide-delete
			>
				<template #right-actions="{item}">
					<v-btn @click="createJob(item.id)" fab x-small class="my-1" elevation="0">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</template>
				<template #item.is_device_replace="{item}">
					<boolean-indicator :value="item.__raw.is_device_replace==1" color-on="error" icon-on="mdi-alert" icon-off="" colored />
				</template>
				<template #item.is_job_exists="{item}">
					<boolean-indicator :value="item.__raw.is_job_exists==1" colored icon-off="" />
				</template>
				<template #select-actions="{selected}">
					<btn-loading-promise :click="()=>multiCreate(selected)">
						<template #default="{click,loading}">
							<v-btn @click="click" :loading="loading" color="primary" small class="ma-1">
								Създай заявки за {{selected.length}} имота
							</v-btn>
						</template>
					</btn-loading-promise>
				</template>
			</List>
		</v-container>
	</my-dialog>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import MyDialog from "@/ittijs/components/MyDialog.vue";
import QuickSearch from "@/ittijs/QuickSearch.vue";
import QuickSearchSet from "@/ittijs/QuickSearchSet.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import StationSelectAll from "@/views/stations/StationSelectAll.vue";
import List from "@/ittijs/List.vue";
import ITTIModel, {FilterOps} from "@/ittijs/ITTIModel";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import BooleanIndicator from "@/ittijs/components/BooleanIndicator.vue";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";

class ImotModel extends ITTIModel {
	constructor() {
		super('ImotiZaMontajnik');
	}
}

const imotModel = ITTIModelProvider.getModel(ImotModel);

export default {
	components : {BtnLoadingPromise, BooleanIndicator, List, StationSelectAll, QuickSearchSet, QuickSearch, MyDialog},
	mixins: [IModelMixin, SelectedUtilityMixin],
	props: {
		date: {},
	},
	computed: {
		filter(){
			return {
				rules: [{
					field: 'utility_code',
					op: FilterOps.EQUALS,
					value: this.selectedUtility,
				}],
				groups: [this.filterQuick],
				glue: 'AND',
			}
		},
	},
	data(){
		return {
			dialog: false,
			imotModel,
			filterQuick: {},
			FilterOps,
		}
	},
	methods: {
		open() {
			this.dialog = true;
		},
		async createJob(imotId) {
			const jobId = await this.imodel.createJob(this.selectedUtility, imotId, this.date);
			this.dialog = false;
			this.$emit('ready', jobId);
		},
		async multiCreate(selected) {
			await this.imodel.createJobs(this.selectedUtility, selected.map(item => item.id), this.date);
			this.dialog = false;
			this.$emit('reload');
		},
	},
}
</script>