<template>
	<div style="display: inline-block;">

		<btn-loading-promise :click="callback1">
			<template #default="{click,loading}">
				<v-btn ref="activator" @click="click" :loading="loading" v-bind="$attrs">
					{{ label }}
				</v-btn>
			</template>
		</btn-loading-promise>

		<my-dialog v-model="dialog" max-width="400" title="Изпращане на отчет">
			<v-container>
				<v-alert type="warning" dense text outlined>
					Липсва имейл адрес!
				</v-alert>
				<v-form v-model="isFormValid">
					<v-radio-group v-model="decision" :rules="[Validators.required()]" dense>
						<v-radio value="no" label="Само запиши PDF документ"></v-radio>
						<v-radio value="yes" label="Въведи имейл за получаване"></v-radio>
					</v-radio-group>
					<v-text-field :disabled="decision!=='yes'" v-model="email" label="Имейл адрес" outlined dense :rules="decision==='yes' ? [Validators.required(), Validators.email()] : []" />
					<v-checkbox :disabled="decision!=='yes'" v-model="emailUpdateImot" label="Запиши имейла в профила на имота" dense />
				</v-form>
			</v-container>

			<template #actions>
				<v-btn small text @click="dialog = false">Откажи</v-btn>
				<v-spacer />
				<btn-loading-promise :click="callback2">
					<template #default="{click,loading}">
						<v-btn :disabled="!isFormValid" small color="primary" @click="click" :loading="loading">Потвърди</v-btn>
					</template>
				</btn-loading-promise>
			</template>
		</my-dialog>

		<v-snackbar color="success" v-model="snackbar" timeout="2000">
			Успех!
		</v-snackbar>

	</div>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "@/views/montaji/montajnik_ui/Model";
import Validators from "@/ittijs/Validators";

const model = ITTIModelProvider.getModel(Model);

export default {
	computed   : {
		Validators() {
			return Validators
		}
	},
	components : {BtnLoadingPromise, MyDialog},
	props: {
		label: {
			type: String,
			default: 'Запиши PDF отчет',
		},
		jobId: {},
	},
	data(){
		return {
			dialog: false,
			snackbar: false,
			isFormValid: false,
			decision: null,
			email: null,
			emailUpdateImot: true,
		}
	},
	methods: {
		open(){ // called from outside
			this.$refs.activator.$el.click();
		},
		initForm() {
			this.decision = null;
			this.email = '';
			this.emailUpdateImot = true;
		},
		async callback1() {
			const res = await model.sendOtchetEmail(this.jobId);
			if (res === false) {
				this.initForm();
				this.dialog = true;
			}
			else {
				this.snackbar = true;
				this.$emit('ready');
			}
		},
		async callback2() {
			if (this.isFormValid) {
				await model.sendOtchetEmail(this.jobId, this.decision === 'no', this.email, this.emailUpdateImot);
				this.dialog = false;
				this.snackbar = true;
				this.$emit('ready');
			}
		},
	},
}
</script>